
import React from 'react'
import PropTypes from 'prop-types'
import classes from './SideBar.scss'

export default class Triangle extends React.Component {
  static maxRotation = 90;
  static duration = 500;

  static propTypes = {
    active: PropTypes.bool.isRequired
  };

  constructor (props) {
    super(props)
    this.filterId = 'id' + (Math.random() * 10000000000000000)
  }

  state = {
    isActive: this.props.active,
    progress: 100
  }

  static getDerivedStateFromProps (props, state) {
    if (props.active !== state.isActive) {
      return {
        isActive: props.active,
        progress: 100 - state.progress
      }
    }
    return null
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextProps.active !== this.props.active ||
      nextState.progress !== this.state.progress
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.active !== this.props.active) {
      this.startTime = undefined
      this._animate()
    }
  }

  componentWillUnmount () {
    cancelAnimationFrame(this.raf)
  }

  _animate () {
    this.raf = requestAnimationFrame((timePassed) => {
      if (!this.startTime) {
        const currentProgress = Triangle.duration * this.state.progress / 100
        this.startTime = timePassed - currentProgress
      }

      let progress = Math.min((timePassed - this.startTime) / Triangle.duration, 1)
      progress = Math.round(progress * 100)
      this.setState({ progress: progress })

      if (progress >= 100) {
        return false
      }
      this._animate()
    })
  }

  render () {
    const { active } = this.props
    const { progress } = this.state

    let rotation = Math.round(Triangle.maxRotation * progress / 100)
    let offset = 5 - (Math.round(10 * progress) / 100)
    if (!active) {
      rotation = Triangle.maxRotation - rotation
      offset = -offset
    }
    return (
      <svg viewBox='0 0 63.3 70' className={classes.triangle} preserveAspectRatio='xMidYMin'>
        <filter id={this.filterId} x='-50%' y='-50%' width='200%' height='200%'>
          <feComponentTransfer in='SourceAlpha'>
            <feFuncA type='table' tableValues='1 0' />
          </feComponentTransfer>
          <feGaussianBlur stdDeviation='3' />
          <feOffset dx='3' dy={offset} result='offsetblur' />
          <feFlood floodColor='rgb(20, 0, 0)' result='color' />
          <feComposite in2='offsetblur' operator='in' />
          <feComposite in2='SourceAlpha' operator='in' />
          <feMerge>
            <feMergeNode in='SourceGraphic' />
            <feMergeNode />
          </feMerge>
        </filter>
        <polygon
          points='20,10 63.3,35.0 20,60'
          transform={'rotate(' + rotation + ')'}
          filter={'url(#' + this.filterId + ')'}
        />
      </svg>
    )
  }
}
