import React from 'react'
import PropTypes from 'prop-types'
import classes from './VerticalScroll.scss'

export default class VerticalScroll extends React.Component {
  static _scrollbarWidth = false;

  static propTypes = {
    scrollMinSize: PropTypes.number,
    hideWhenNotNeeded: PropTypes.bool,
    // autoHide: PropTypes.bool,
    // autoHideTimeout: PropTypes.number,
    // autoHideDuration: PropTypes.number,
    // universal: PropTypes.bool,

    children: PropTypes.node,
    className: PropTypes.string
  }

  static defaultProps = {
    scrollMinSize: 30,
    hideWhenNotNeeded: true
  //   autoHide: false,
  //   autoHideTimeout: 1000,
  //   autoHideDuration: 200,
  //   universal: false
  }

  constructor (props) {
    super(props)
    this.state = { didMountUniversal: false }
  }

  componentDidMount () {
    this._addListeners()
    this._updateScrollBar()
  }

  componentDidUpdate () {
    this._updateScrollBar()
  }

  componentWillUnmount () {
    this._removeListeners()
    clearTimeout(this.hideTracksTimeout)
    clearInterval(this.detectScrollingInterval)
  }

  _handleScroll = () => {
    this._updateScrollBar()
  }

  _handleTrackMouseDown = () => {
    const { top, bottom } = this._scrollBar.getBoundingClientRect()
    const middle = top + (bottom - top) / 2
    const scrollDiff = (middle - event.clientY) * this._startDragRatio
    this._scrollView.scrollTop = this._scrollView.scrollTop - scrollDiff
  }

  _handleScrollBarMouseDown = (event) => {
    this._dragging = true
    event.stopImmediatePropagation()

    // Set values for drag that won't change while draging
    this._startDragRatio = this._scrollView.scrollHeight / this._scrollTrack.offsetHeight
    this._startDragClientY = event.clientY
    this._startDragScrollTop = this._scrollView.scrollTop

    document.body.classList.add(classes.disableSelect)
    document.addEventListener('mousemove', this._handleDrag)
    document.addEventListener('mouseup', this._handleDragEnd)
    document.onselectstart = () => false
  }

  _handleDrag = (event) => {
    const scrollDiff = (this._startDragClientY - event.clientY) * this._startDragRatio
    this._scrollView.scrollTop = this._startDragScrollTop - scrollDiff
    return false
  }

  _handleDragEnd = () => {
    this.dragging = false
    this.prevPageX = this.prevPageY = 0
    document.body.classList.remove(classes.disableSelect)
    document.removeEventListener('mousemove', this._handleDrag)
    document.removeEventListener('mouseup', this._handleDragEnd)
    document.onselectstart = undefined
  }

  _handleWindowResize = () => {
    this._updateScrollBar()
  }

  _addListeners = () => {
    if (typeof this._scrollView === 'undefined') {
      return
    }
    this._scrollView.addEventListener('scroll', this._handleScroll)
    if (!this._scrollbarWidth) {
      return
    }
    this._scrollTrack.addEventListener('mousedown', this._handleTrackMouseDown)
    this._scrollBar.addEventListener('mousedown', this._handleScrollBarMouseDown)
    window.addEventListener('resize', this._handleWindowResize)
  }

  _removeListeners = () => {
    if (typeof document === 'undefined') {
      return
    }
    if (this._scrollView) {
      this._scrollView.removeEventListener('scroll', this._handleScroll)
    }
    if (this._scrollTrack) {
      this._scrollTrack.removeEventListener('mousedown', this._handleTrackMouseDown)
    }
    if (this._scrollBar) {
      this._scrollBar.removeEventListener('mousedown', this._handleScrollBarMouseDown)
    }
    window.removeEventListener('resize', this._handleWindowResize)
    // Possibly setup by `_handleDrag`
    this._handleDragEnd()
  }

  _updateScrollBarRunning = false;
  _updateScrollBar = () => {
    // Prevent duplicate instances running
    if (this._updateScrollBarRunning) return

    this._updateScrollBarRunning = true
    let oldInnerHeight = null
    let animationCount = 0

    const updateScrollBarInternal = () => {
      if (!this._scrollView) return

      const innerHeight = this._scrollView.scrollHeight
      // This allows us to keep updateing if the children are animating.
      if (oldInnerHeight === innerHeight) {
        // Sometimes the child animation stalls so we need to allow for a few frames of stalled animation.
        if (animationCount < 5) {
          this._updateScrollBarRunning = false
          animationCount++
          requestAnimationFrame(updateScrollBarInternal)
        }
        return
      }
      animationCount = 0
      oldInnerHeight = innerHeight

      // _scrollTrack doesn't change size properly so use the convolulted method to get the correct size :(
      const trackProps = window.getComputedStyle(this._scrollTrack)
      let outerHeight = this._scrollView.offsetHeight

      if (outerHeight >= innerHeight && this.props.hideWhenNotNeeded) {
        this._scrollBar.style.height = 0
        requestAnimationFrame(updateScrollBarInternal)
        return
      }
      outerHeight = outerHeight - parseInt(trackProps.getPropertyValue('top')) -
        parseInt(trackProps.getPropertyValue('bottom'))

      let scrollBarHeight = outerHeight * (outerHeight / innerHeight)
      scrollBarHeight = this.props.scrollMinSize > scrollBarHeight ? this.props.scrollMinSize : scrollBarHeight
      const scrollBarPosition = (outerHeight - scrollBarHeight) *
        this._scrollView.scrollTop / (innerHeight - outerHeight)

      this._scrollBar.style.height = `${scrollBarHeight}px`
      this._scrollBar.style.transform = `translateY(${scrollBarPosition}px)`
      // console.log('_updateScrollBar: ' + innerHeight + 'px')

      requestAnimationFrame(updateScrollBarInternal)
    }
    requestAnimationFrame(updateScrollBarInternal)
  }

  // gets the static prop for scrollbar width or sets it if it hasn't been set
  get _scrollbarWidth () {
    if (VerticalScroll._scrollbarWidth !== false) {
      return VerticalScroll._scrollbarWidth
    }
    if (typeof document !== 'undefined') {
      const div = document.createElement('div')
      div.style.position = 'absolute'
      div.style.top = '0'
      div.style.left = '0'
      div.style.right = '0'
      div.style.bottom = '0'
      div.style['overflow-y'] = 'scroll'

      document.body.appendChild(div)
      VerticalScroll._scrollbarWidth = (div.offsetWidth - div.clientWidth)
      document.body.removeChild(div)
    } else {
      VerticalScroll._scrollbarWidth = 0
    }
    return VerticalScroll._scrollbarWidth
  };

  render () {
    /* eslint-disable no-unused-vars */
    const { children, className, scrollMinSize, hideWhenNotNeeded, ...otherProps } = this.props
    /* eslint-restore no-unused-vars */
    return (
      <div
        {...otherProps}
        className={className + ' ' + classes.containerStyleDefault}
      >
        <div
          className={classes.scrollView}
          style={{ marginRight: -this._scrollbarWidth }}
          ref={(ref) => { this._scrollView = ref }}
        >
          {children}
        </div>
        <div className={classes.scrollTrack} ref={(ref) => { this._scrollTrack = ref }}>
          <div className={classes.scrollBar} ref={(ref) => { this._scrollBar = ref }} />
        </div>
      </div>
    )
  }
};
