
import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import classes from './FadeInOut.scss'

const FadeInOut = ({ children }) =>
  <TransitionGroup>
    {(children
    /* eslint-disable indent */
    /* eslint-disable react/no-children-prop */
      ? <CSSTransition
          children={children}
          classNames={{
            appear: classes.appear,
            appearActive: classes.appearActive,
            enter: classes.enter,
            enterActive: classes.enterActive,
            exit: classes.exit,
            exitActive: classes.exitActive
          }}
          appear={true}
          timeout={{ enter: 500, exit: 500, appear: 500 }}
      />
    /* eslint-enable indent */
    /* eslint-enable react/no-children-prop */
      : null)}
  </TransitionGroup>

FadeInOut.propTypes = {
  children: PropTypes.element
}
export default FadeInOut
