import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectPhoto, unselectPhoto } from 'redux/modules/photos'
import FadeInOut from 'components/Animation/FadeInOut'
import LoadingImage from 'components/LoadingImage'
import CameraImage from 'components/CameraImage'
import classes from './PhotoViewer.scss'
import PhotoShape from './PhotoShape'
import CanUseWebP from 'functions/canUseWebP'

export class PhotoViewer extends React.Component {
  static cacheSize = 5;

  static propTypes = {
    selectedPhoto: PropTypes.number,
    photoData: PropTypes.arrayOf(PhotoShape).isRequired,
    selectPhoto: PropTypes.func.isRequired,
    unselectPhoto: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this._isDrawn = false
    this.state = {
      previousSelectedPhoto: this.props.selectedPhoto,
      ready: false,
      data: null,
      transitionDuration: 0,
      transitioning: true,
      width: 0,
      height: 0,
      isVideo: false
    }
  }

  static getDerivedStateFromProps (props, state) {
    if (!(props.selectedPhoto in props.photoData)) {
      return { width: 0, height: 0 }
    }

    if (state.previousSelectedPhoto !== props.selectedPhoto) {
      return { previousSelectedPhoto: props.selectedPhoto, ready: false, data: null, transitioning: true }
    }

    return null
  }

  componentDidMount () {
    if (this._photoSelectable(this.props.selectedPhoto)) {
      this._isDrawn = true
      this._loadImage(this.props.selectedPhoto)
    }
    window.addEventListener('keydown', this._nextPhoto)
    window.addEventListener('keydown', this._previousPhoto)
    window.addEventListener('resize', this._resizePhoto)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextProps.selectedPhoto !== this.props.selectedPhoto ||
      nextState.ready !== this.state.ready ||
      nextState.transitioning !== this.state.transitioning ||
      nextState.width !== this.state.width ||
      nextState.height !== this.state.height
  }

  componentDidUpdate (prevProps, prevState) {
    this._isDrawn = this._photoSelectable(this.props.selectedPhoto)

    if (this._isDrawn && this.props.selectedPhoto !== prevProps.selectedPhoto) {
      this._loadImage(this.props.selectedPhoto)
    }

    // if the duration is 0 then the image won't transition so we have to fire the visibility manually
    if (this.state.transitionDuration === 0) {
      window.clearTimeout(this.fadeInTimeout)
      this.fadeInTimeout = window.setTimeout(this._fadeInPhoto)
    }
  }

  componentWillUnmount () {
    this._isDrawn = false
    window.removeEventListener('keydown', this._nextPhoto)
    window.removeEventListener('keydown', this._previousPhoto)
    window.removeEventListener('resize', this._resizePhoto)
  }

  _loadImage = (photoIndex) => {
    if (!this._photoSelectable(photoIndex) || !this._isDrawn) {
      return
    }
    // get photo promise from cache.
    const cacheObject = this._getCachedImage(photoIndex, photoIndex)
    if (cacheObject.resolved && this._isDrawn && cacheObject.objectUrl) {
      this._setImage(cacheObject.objectUrl, cacheObject.width, cacheObject.height, cacheObject.isVideo)
    } else {
      this.setState({ ready: false, data: null })
      cacheObject.promise.then((resolvedCache) => {
        if (!this._isDrawn && resolvedCache.objectUrl) {
          return
        }
        this._setImage(resolvedCache.objectUrl, resolvedCache.width, resolvedCache.height, resolvedCache.isVideo)
      })
    }
  }

  _setImage = (data, imageWidth, imageHeight, isVideo) => {
    const { width, height } = this._getViewSize(imageWidth, imageHeight)
    const duration =
        // Max change in distance as a percentage of max possible distance
        Math.max(Math.abs(width - this.state.width), Math.abs(height - this.state.height)) /
        Math.max(window.innerWidth, window.innerHeight)

    this.setState(Object.assign({
      ready: true,
      data: data,
      transitionDuration: duration,
      width: width,
      height: height,
      isVideo: isVideo
    }))
  }

  _getCachedImage = (photoIndex, selectedIndex) => {
    if (!this._photoSelectable(photoIndex)) {
      // console.log('Photo Unselectable: ' + photoIndex);
      return null
    }

    if (!this._isDrawn ||
      photoIndex + 1 > selectedIndex + PhotoViewer.cacheSize ||
      !(photoIndex in this.props.photoData)) {
      // console.log('All photos cached');
      // Return if we have either cached all the files we require,
      // have reached the end of the photo album or the modal is no-longer on screen
      this._cleanCache(selectedIndex)
      return null
    }

    const src = this._getSrc(photoIndex)
    if (photoIndex in this._photoCache && this._photoCache[photoIndex] && this._photoCache[photoIndex].src === src) {
      // If we have already cached and the cached file is correct then move to the next image.
      this._getCachedImage(photoIndex + 1, selectedIndex)
      // then return the current promise from the cache.
      return this._photoCache[photoIndex]
    }

    const cacheObject = {
      src: src,
      promise: null,
      resolved: false,
      objectUrl: null,
      width: null,
      height: null,
      isVideo: false
    }

    cacheObject.promise = fetch(src,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'default',
        headers: CanUseWebP() ? { accept: 'image/webp' } : {}
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // cache next photo
        this._getCachedImage(photoIndex + 1, selectedIndex)

        cacheObject.objectUrl = URL.createObjectURL(blob)
        if (blob.type.includes('video')) {
          cacheObject.height = this.props.photoData[photoIndex].height
          cacheObject.width = this.props.photoData[photoIndex].width
          cacheObject.resolved = true
          cacheObject.isVideo = true
          return cacheObject
        }

        return new Promise((resolve, reject) => {
          const image = new Image()
          image.src = cacheObject.objectUrl
          image.onload = () => {
          // Update the cached object so we can access externally
            cacheObject.width = image.width
            cacheObject.height = image.height
            cacheObject.resolved = true
            resolve(cacheObject)
          }
        })
      })
      .catch(() => { delete this._photoCache[photoIndex] })

    this._photoCache[photoIndex] = cacheObject

    // console.log('Cached: ' + photoIndex);
    return cacheObject
  }

  _cleanCache (selectedIndex) {
    for (const key in this._photoCache) {
      if (key <= (selectedIndex - PhotoViewer.cacheSize)) {
        delete this._photoCache[key]
        // console.log('deleted: ' + key + ' index: ' + selectedIndex);
      }
    }
  }

  _photoCache = [];

  _photoSelectable = (photoIndex) => {
    return photoIndex in this.props.photoData
  }

  _getSrc = (photoIndex) => {
    if (!this._isDrawn) {
      // we can't calculate the height and width if the modal is un-drawn
      throw new Error('can\'t generate src if modal is un-drawn')
    }
    const media = this.props.photoData[photoIndex]

    const { width, height } = this._getViewSize(media.width, media.height)

    return (`https://${__APIURI__}/${escape(media.fileName)}?width=${width}&height=${height}`)
  }

  _getViewSize = (imageWidth, imageHeight) => {
    const width = window.innerWidth -
      (this._overlayRef.offsetWidth - this._overlayRef.clientWidth) -
      (this._modalRef.offsetWidth - this._mediaHolderRef.offsetWidth)

    const height = window.innerHeight -
        (this._overlayRef.offsetHeight - this._overlayRef.clientHeight) -
        (this._modalRef.offsetHeight - this._mediaHolderRef.offsetHeight)

    const percent = Math.min(width / imageWidth, height / imageHeight)

    return {
      width: Math.round(imageWidth * percent),
      height: Math.round(imageHeight * percent)
    }
  }

  get _fullImageUrl () {
    const photoName = escape(this.props.photoData[this.props.selectedPhoto].fileName)

    return (`https://${__APIURI__}/${photoName}`)
  }

  _closeViewer = (event) => {
    event.stopPropagation()
    this.setState({ ready: false, transitioning: false })
    this.props.unselectPhoto()
  }

  _previousPhoto = (event) => {
    if (event.type === 'click' || event.keyCode === 37) {
      event.stopPropagation()
      this.props.selectPhoto(this.props.selectedPhoto - 1)
    }
  }

  _nextPhoto = (event) => {
    if (event.type === 'click' || event.keyCode === 39) {
      event.stopPropagation()
      this.props.selectPhoto(this.props.selectedPhoto + 1)
    }
  }

  _stopPropagation = (event) => {
    event.stopPropagation()
  }

  _fadeInPhoto = () => {
    if (this.state.transitioning) {
      this.setState({ transitioning: !this.state.ready })
    }
  }

  _resizePhoto = () => {
    if (this.props.photoData.length === 0) return { width: 0, height: 0 }
    const mediaItem = this.props.photoData[this.props.selectedPhoto]
    if (!mediaItem) return { width: 0, height: 0 }

    const { width, height } = mediaItem
    const { width: viewWidth, height: viewHeight } = this._getViewSize(width, height)
    this.setState({
      width: viewWidth,
      height: viewHeight
    })
  }

  render () {
    const { selectedPhoto, photoData } = this.props
    const { ready, transitionDuration, transitioning, data, width, height, isVideo } = this.state

    let returnElement = null
    if (this._photoSelectable(this.props.selectedPhoto)) {
      const sizerStyle = {
        width: (width + 'px'),
        height: (height + 'px'),
        transitionDuration: transitionDuration + 's'
      }

      returnElement = (
        <div className={classes.overlay} onClick={this._closeViewer} ref={(c) => { this._overlayRef = c }}>
          <div className={classes.modal} ref={(c) => { this._modalRef = c }}>
            <div className={classes.photoHolder} style={sizerStyle} ref={(c) => { this._mediaHolderRef = c }} onTransitionEnd={this._fadeInPhoto}>
              {ready
                ? (isVideo
                  ? <video src={data} autoPlay={true} muted={true} loop={true} />
                  : <img className={classes.photo + (transitioning ? '' : ' ' + classes.visible)} src={data} />)
                : <LoadingImage />}{isVideo}
              <div className={classes.previous} onClick={this._previousPhoto} />
              <div className={classes.next} onClick={this._nextPhoto} />
            </div>
            <div className={classes.info} onClick={this._stopPropagation}>
              <div>
                <a className={classes.downloadImage} download='' href={this._fullImageUrl}>
                  <CameraImage />
                </a>
                {selectedPhoto + 1} of {photoData.length}
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <FadeInOut>
        {returnElement}
      </FadeInOut>
    )
  }
}

export default connect(null, {
  selectPhoto,
  unselectPhoto
})(PhotoViewer)
