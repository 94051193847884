import React from 'react'
import PropTypes from 'prop-types'
import classes from './SideBar.scss'

export default function Hamburger ({ open, onClick }) {
  return (
    <svg
      className={classes.hamburger + (open ? ' ' + classes.open : '')} onClick={onClick}
      x='0px' y='0px' viewBox='0 0 150 125' enableBackground='new 0 0 150 125'
    >
      <rect y='0' width='150' height='25' />
      <rect y='50' width='150' height='25' />
      <rect y='100' width='150' height='25' />
    </svg>
  )
}

Hamburger.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}
