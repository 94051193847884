import React from 'react'
import Helmet from 'react-helmet'
import classes from './ContactView.scss'

import HeadingUnderlined from 'components/HeadingUnderlined'

export const ContactView = () => {
  const addressLink = 'maxqyn Rice<emlynribfhetreehousegnetgnz>'
    .replace(/x/g, 'il')
    .replace(/q/g, 'to:Eml')
    .replace(/b/g, 'ce')
    .replace(/f/g, '@t')
    .replace(/g/g, '.')

  return (
    <div className='content'>
      <Helmet
        title='Contact Emlyn'
        meta={[{ name: 'description', content: 'Emlyn\'s conttact info' }]}
      />
      <div className={classes.picture} />
      <HeadingUnderlined centered={true}>
        Emlyn Rice
      </HeadingUnderlined>
      <p className={classes.tagLine}>Application Developer & Traveller.</p>
      <ul className={classes.social}>
        <li>
          <a data-tooltip='Follow me on Twitter' href='http://www.twitter.com/norfrin'>twitter</a>
        </li>
        <li>
          <a data-tooltip='Follow me on Linkedin' href='www.linkedin.com/in/emlyn-rice'>linkedin</a>
        </li>
        <li>
          <a data-tooltip='Contact me via email' href={addressLink}>email</a>
        </li>
      </ul>
    </div>
  )
}

export default ContactView
