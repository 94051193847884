import Helmet from 'react-helmet'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PhotoBox from './components/PhotoBox'
import PhotoShape from './components/PhotoShape'
import PhotoSizer from './components/PhotoSizer'
import PhotoViewer from './components/PhotoViewer'

import { NotFoundView } from 'views/NotFoundView/NotFoundView'

import { statuses, getPhotoData } from 'redux/modules/photos'
import HeadingUnderlined from 'components/HeadingUnderlined'
import getPhotoFolderName from 'functions/getPhotoFolderName'
import classes from './PhotoView.scss'

// We avoid using the `@connect` decorator on the class definition so
// that we can export the undecorated component for testing.
// See: http://rackt.github.io/redux/docs/recipes/WritingTests.html
export class PhotoView extends React.Component {
  static propTypes = {
    photoData: PropTypes.arrayOf(PhotoShape).isRequired,
    photoDataStatus: PropTypes.oneOf(Object.values(statuses)).isRequired,
    loadedFolder: PropTypes.string.isRequired,
    selectedPhoto: PropTypes.number,
    folderName: PropTypes.string,
    getPhotoData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor (props) {
    super(props)
    if (props.loadedFolder !== props.folderName) this.props.getPhotoData(this.props.folderName)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextProps.folderName !== this.props.folderName ||
      this.props.photoData !== nextProps.photoData ||
      this.props.selectedPhoto !== nextProps.selectedPhoto ||
      this.props.photoDataStatus !== nextProps.photoDataStatus
  }

  componentDidUpdate () {
    if (this.props.loadedFolder !== this.props.folderName) {
      this.props.getPhotoData(this.props.folderName)
    }
  }

  get _pictureNodes () {
    if (this.props.loadedFolder !== this.props.folderName) return null

    const photoSizer = new PhotoSizer(this.props.photoData.length)
    const pictureNodes = []

    for (const photo of this.props.photoData) {
      pictureNodes.push(
        <PhotoBox
          size={160}
          selectedPhoto={this.props.selectedPhoto}
          {...photoSizer.getPictureSizing(photo)}
        />)
      pictureNodes.push(...photoSizer.getSpacers())
    }
    return pictureNodes
  }

  _helmet = (folderName) =>
    <Helmet
      title={folderName}
      titleTemplate='The Treehouse Photos - %s'
      meta={[{ name: 'description', content: 'Photos from ' + folderName }]}
    />

  render () {
    const folderName = getPhotoFolderName(this.props.folderName)
    if (folderName === '') return (<div key={this.props.folderName} />)

    switch (this.props.photoDataStatus) {
      case statuses.UNLOADED:
      case statuses.LOADING:
        return (
          <div key={this.props.folderName}>
            {this._helmet(folderName)}
            <HeadingUnderlined>{this.props.photoDataStatus + '...'}</HeadingUnderlined>
          </div>
        )
      case statuses.LOADED:
        return (
          <div key={this.props.folderName}>
            {this._helmet(folderName)}
            <HeadingUnderlined>{folderName}</HeadingUnderlined>
            <div className={classes.photoHolder}>
              {this._pictureNodes}
            </div>
            <PhotoViewer photoData={this.props.photoData} selectedPhoto={this.props.selectedPhoto} />
          </div>
        )
      case statuses.NOTFOUND:
      case statuses.ERROR:
        return <NotFoundView key={this.props.folderName} history={this.props.history} />
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  photoData: state.photos.data,
  photoDataStatus: state.photos.photoDataStatus,
  loadedFolder: state.photos.currentFolder,
  selectedPhoto: state.photos.selectedPhoto,
  folderName: ownProps.match.params.folder || ''
})

export default connect(mapStateToProps, {
  getPhotoData
})(PhotoView)
