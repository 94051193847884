import React from 'react'
import PropTypes from 'prop-types'
import classes from './HeadingUnderlined.scss'

function splitByLength (str, maxLength) {
  const index = str.indexOf(' ', maxLength)
  if (index !== -1) {
    return [str.substring(0, index), ...splitByLength(str.substring(index), maxLength)]
  }
  return [str]
}

const HeadingUnderlined = ({ children, centred }) => {
  const maxLength = 25

  if (children.length > maxLength) {
    const splitTitle = []

    for (const split of children.split(' - ')) {
      splitTitle.push(...splitByLength(split, 25))
    }

    const splitHeading = splitTitle.map((value, index) =>
      <h1 key={index} className={classes.visibleMobile}>{value}</h1>
    )

    return (
      <div className={centred ? classes.headingUnderlinedCentred : classes.headingUnderlined}>
        <h1 className={classes.hiddenMobile}>{children}</h1>
        {splitHeading}
      </div>
    )
  }

  return (
    <div className={centred ? classes.headingUnderlinedCentred : classes.headingUnderlined}>
      <h1>{children}</h1>
    </div>
  )
}

HeadingUnderlined.propTypes = {
  children: PropTypes.string.isRequired,
  centred: PropTypes.bool
}

HeadingUnderlined.defaultProps = {
  centered: false
}

export default HeadingUnderlined
