import React from 'react'
import PropTypes from 'prop-types'
import classes from '../PhotoView.scss'

const Spacer = (props) => {
  return (
    <div className={classes.photoBox + ' ' + classes.oneToOne + ' ' + classes[props.spacerClass]}>
      <div className={classes.rowSizer} />
    </div>
  )
}

Spacer.propTypes = {
  spacerClass: PropTypes.oneOf(['spacer3', 'spacer6', 'spacer9', 'spacer12'])
}

export default Spacer
