import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

const Root = ({ routes, store, history }) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history} basename={__BASENAME__}>
        {routes}
      </ConnectedRouter>
    </Provider>
  )
}

Root.propTypes = {
  routes: PropTypes.element.isRequired,
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default Root
