import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toggleMenu, getDirectoryData } from 'redux/modules/sideBar'
import Hamburger from './Hamburger'
import MenuItem from './MenuItem'
import DirectoryShape from './DirectoryShape'
import VerticalScroll from 'containers/VerticalScroll'
import FadeInOut from 'components/Animation/FadeInOut'
import classes from './SideBar.scss'

export class SideBar extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    selectedPath: PropTypes.string.isRequired,
    selectedRoute: PropTypes.string.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    directoryData: PropTypes.arrayOf(DirectoryShape),
    toggleMenu: PropTypes.func.isRequired,
    getDirectoryData: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.getDirectoryData()
  }

  shouldComponentUpdate (nextProps, nextState) {
    return this.props.selectedPath !== nextProps.selectedPath ||
      this.props.menuOpen !== nextProps.menuOpen ||
      this.props.directoryData !== nextProps.directoryData
  }

  render () {
    const { className, toggleMenu, menuOpen, directoryData, selectedPath, selectedRoute } = this.props
    const menuItemProps = { selectedPath, selectedRoute }

    return (
      <nav className={className + ' ' + classes.sideBar}>
        <div className={classes.track}>
          <Hamburger open={menuOpen} onClick={toggleMenu} />
        </div>
        <VerticalScroll className={classes.menu + (menuOpen ? ' ' + classes.open : '')}>
          <ul className={classes.menuRoot}>
            {
              /* istanbul ignore next : ignore dev only branch */
              __DEV__ ? <MenuItem to='/test' {...menuItemProps}>Test</MenuItem> : null
            }
            <MenuItem to='/contact' {...menuItemProps}>Contact</MenuItem>
            <MenuItem to='/photos' subFolders={directoryData} {...menuItemProps}>Photos</MenuItem>
          </ul>
        </VerticalScroll>
        <FadeInOut>
          {(menuOpen
            ? <div className={classes.overlay} onClick={toggleMenu} key={0} />
            : null
          )}
        </FadeInOut>
      </nav>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedPath: state.sideBar.selectedPath,
  selectedRoute: state.sideBar.selectedRoute,
  menuOpen: state.sideBar.menuOpen,
  directoryData: state.sideBar.directoryData
})

export default connect(mapStateToProps, {
  toggleMenu,
  getDirectoryData
})(SideBar)
