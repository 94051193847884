import { actions as errorActions } from './error'
// ------------------------------------
// Constants
// ------------------------------------
export const LOAD_PHOTO_DATA = 'LOAD_PHOTO_DATA'
export const SELECT_PHOTO = 'SELECT_PHOTO'
export const UNSELECT_PHOTO = 'UNSELECT_PHOTO'
export const SET_PHOTO_DATA_STATUS = 'SET_PHOTO_DATA_STATUS'

export const statuses = {
  UNLOADED: 'UNLOADED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  NOTFOUND: 'NOTFOUND',
  ERROR: 'ERROR'
}

// ------------------------------------
// Actions
// ------------------------------------
export function loadPhotoData (folder = '', photoData = []) {
  return {
    type: LOAD_PHOTO_DATA,
    payload: {
      data: photoData,
      folder: folder
    }
  }
}

export function setPhotoDataStatus (status, folder) {
  if (!statuses[status]) throw Object({ error: true, message: 'status does not match a known status' })
  if (status === statuses.LOADED) throw Object({ error: true, message: 'Status LOADED should only be set through the loadPhotoData action' })
  if (status === statuses.LOADING && !folder) throw Object({ error: true, message: 'Loading requires that we spesify the folder' })

  return {
    type: SET_PHOTO_DATA_STATUS,
    payload: {
      status,
      folder: status === statuses.LOADING ? folder : null
    }
  }
}

export function selectPhoto (photoIndex = 0) {
  return {
    type: SELECT_PHOTO,
    payload: photoIndex
  }
}

export function unselectPhoto () {
  return {
    type: UNSELECT_PHOTO
  }
}

export function getPhotoData (folder) {
  const escapedFolder = escape((folder || '').trim())
  if (escapedFolder === '') {
    // If no folder is selected then return an empty array
    return loadPhotoData()
  }

  return (dispatch, getState) => {
    if (getState().photos.photoDataStatus === statuses.LOADING) {
      // currently loading protect from duplication
      return new Promise((resolve, reject) => {
        resolve({}) // return a blank object
      })
    }
    dispatch(setPhotoDataStatus(statuses.LOADING, folder))
    return fetch(`https://${__APIURI__}/${escapedFolder}`)
      .then((res) => {
        if (res.status >= 400) {
          dispatch(setPhotoDataStatus(statuses.NOTFOUND))
          return res.json().then(
            (json) => dispatch(errorActions.server404Error(json)),
            (error) => dispatch(errorActions.jsonDataError(error))
          )
        }
        return res.json().then(
          (json) => dispatch(loadPhotoData(folder, json)),
          (error) => {
            dispatch(setPhotoDataStatus(statuses.ERROR))
            dispatch(errorActions.jsonDataError(error))
          }
        )
      })
  }
}

export const actions = {
  loadPhotoData,
  getPhotoData,
  selectPhoto,
  unselectPhoto,
  setPhotoDataStatus
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOAD_PHOTO_DATA]: (state, payload) =>
    Object.assign({}, state, { data: payload.data, currentFolder: payload.folder, photoDataStatus: statuses.LOADED }),

  [SELECT_PHOTO]: (state, payload) => {
    let selectedPhoto = payload
    if (selectedPhoto < 0 || selectedPhoto >= state.data.length) {
      selectedPhoto = null
    }
    return Object.assign({}, state, { selectedPhoto: selectedPhoto })
  },

  [UNSELECT_PHOTO]: (state) => {
    if (state.selectedPhoto !== null) {
      return Object.assign({}, state, { selectedPhoto: null })
    }
    return state
  },

  [SET_PHOTO_DATA_STATUS]: (state, payload) => {
    const status = payload.status
    const currentFolder = payload.folder
    if (!statuses[status] || state.photoDataStatus === status) return state

    if (status !== statuses.LOADING) {
      return Object.assign({}, state, { photoDataStatus: status })
    }
    if (currentFolder) {
      return Object.assign({}, state, { photoDataStatus: status, currentFolder, data: [] })
    }
    return state
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  data: [],
  photoDataStatus: statuses.UNLOADED,
  currentFolder: '',
  selectedPhoto: null
}
export default function photoDataReducer (state = Object.assign({}, initialState), action = {}) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action.payload) : state
}
