import { push, LOCATION_CHANGE } from 'connected-react-router'
import { actions as errorActions } from './error'
import getPhotoFolderName from 'functions/getPhotoFolderName'

// ------------------------------------
// Constants
// ------------------------------------
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const TOGGLE_SELECTED_PATH = 'TOGGLE_SELECTED_PATH'
export const LOAD_DIRECTORY_DATA = 'LOAD_DIRECTORY_DATA'
const PHOTO_BASE_URL = '/photos'

// ------------------------------------
// Actions
// ------------------------------------
// DOUBLE NOTE: there is currently a bug with babel-eslint where a `space-infix-ops` error is
// incorrectly thrown when using arrow functions, hence the oddity.
export function toggleMenu () {
  return {
    type: TOGGLE_MENU
  }
}
export function togglePath (path = PHOTO_BASE_URL, show = null) {
  return {
    type: TOGGLE_SELECTED_PATH,
    payload: { path: path, show: show }
  }
}
export function loadDirectoryData (directoryData = []) {
  return {
    type: LOAD_DIRECTORY_DATA,
    payload: directoryData
  }
}

export function getDirectoryData () {
  return (dispatch, getState) => fetch(`https://${__APIURI__}/`)
    .then((res) => res.json())
    .then(
      function processKeys (json, folderName) {
        if (!folderName) {
          folderName = PHOTO_BASE_URL
        }
        const directoryData = []
        for (const key in json) {
          const subFolderName = folderName + '/' + key
          const folder = {
            folder: subFolderName,
            folderName: getPhotoFolderName(subFolderName, true)
          }

          if (Object.keys(json[key] || {}).length > 0) {
            folder.subFolders = processKeys(json[key], subFolderName)
          }
          directoryData.push(folder)
        }
        return directoryData
      },
      (error) => {
        dispatch(errorActions.jsonDataError(error))
        throw error
      }
    )
    .then(
      (directoryData) => {
        directoryData = directoryData.reverse()
        dispatch(loadDirectoryData(directoryData))
        return directoryData
      }
    )
    .then(function getFirstDirectory (directoryData) {
      const firstDir = directoryData[0]
      if ('subFolders' in firstDir) {
        return getFirstDirectory(firstDir.subFolders)
      }
      return firstDir.folder
    })
    .then((path) => {
      if (getState().sideBar.selectedRoute === PHOTO_BASE_URL) {
        dispatch(push({ pathname: path }))
      }
    }).catch((e) => console.log(e))
}

export const actions = {
  toggleMenu,
  togglePath,
  loadDirectoryData,
  getDirectoryData
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOGGLE_MENU]: (state, payload) => Object.assign({}, state, { menuOpen: !state.menuOpen }),

  [TOGGLE_SELECTED_PATH]: (state, payload) => {
    let selectedFolder = payload.path
    const selectedRouteArr = state.selectedRoute.split('/').filter((value) => value.trim() !== '')
    if (state.selectedPath.indexOf(selectedFolder) === 0 || payload.show === false) {
      // going down
      const selectedFolderArr = selectedFolder.split('/')
      selectedFolderArr.pop()
      selectedFolder = selectedFolderArr.join('/')
    } else {
      // going up
      const activeRoute = selectedRouteArr[selectedRouteArr.length - 2]
      if (selectedRouteArr.length >= 2 &&
        // Replacement for ends with
        selectedFolder.lastIndexOf(activeRoute) === (selectedFolder.length - activeRoute.length)) {
        // select the active route if you select it's parent.
        selectedFolder = state.selectedRoute
      }
    }
    return Object.assign({}, state, { selectedPath: selectedFolder })
  },

  [LOCATION_CHANGE]: (state, payload) => {
    const pathName = unescape(payload.location.pathname)
    const selectedPath = pathName.indexOf(PHOTO_BASE_URL) === 0 ? pathName : PHOTO_BASE_URL
    return Object.assign({}, state, { selectedPath: selectedPath, selectedRoute: pathName })
  },

  [LOAD_DIRECTORY_DATA]: (state, payload) => {
    return Object.assign({}, state, { directoryData: payload })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  menuOpen: false,
  directoryData: [],
  selectedPath: '',
  selectedRoute: ''
}
export default function sideBarReducer (state = Object.assign({}, initialState), action = {}) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action.payload) : state
}
