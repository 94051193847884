// Global
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectPhoto } from 'redux/modules/photos'
import LoadingImage from 'components/LoadingImage'
import CanUseWebP from 'functions/canUseWebP'

// View
import classes from '../PhotoView.scss'

export class PhotoBox extends React.Component {
  static propTypes = {
    // These are required in functions
    fileName: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    size: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
    ratio: PropTypes.oneOf(['oneToOne', 'doubleWidth', 'tripleWidth', 'doubleHeight',
      'tripleHeight', 'double']),
    className: PropTypes.string,
    index: PropTypes.number.isRequired,
    selectedPhoto: PropTypes.number,
    selectPhoto: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      isSelected: props.selectedPhoto === props.index,
      showImage: false,
      imgData: null,
      pulsate: false
    }
  }

  static getDerivedStateFromProps (props, state) {
    return {
      isSelected: props.selectedPhoto === props.index,
      bounce: state.isSelected && props.selectedPhoto === null
    }
  }

  componentDidMount () {
    this._isMounted = true
    this._addListeners()
  }

  shouldComponentUpdate (nextProps, nextState) {
    return this._getSrc(this.props) !== this._getSrc(nextProps) ||
      nextState.showImage !== this.state.showImage ||
      nextState.bounce !== this.state.bounce
  }

  componentDidUpdate (prevProps, prevState) {
    if (this._getSrc(this.props) !== this._getSrc(prevProps)) {
      this._detachListeners()
      this._addListeners()
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
    this._detachListeners()
  }

  _lazyLoadHandler = () => {
    const rect = this._selfRef ? this._selfRef.getBoundingClientRect() : {}
    if (rect.top >= 0 && rect.left >= 0 && // rect is below the top of the screen
      // rect is up to 4 lines below the bottom of the screen
      rect.top <= window.innerHeight + (rect.bottom - rect.top) * 4 &&
      this._isMounted) {
      fetch(this._getSrc(this.props),
        {
          method: 'GET',
          mode: 'cors',
          cache: 'default',
          headers: CanUseWebP() ? { accept: 'image/webp' } : {}
        })
        .then((response) => response.blob())
        .then((myBlob) => {
          if (this._isMounted) {
            this.setState({
              showImage: true,
              imgData: URL.createObjectURL(myBlob)
            })
          }
        })
      this._detachListeners()
      return true
    }
    return false
  }

  _getSrc = (props) => {
    const { fileName, size, ratio } = props
    return `https://${__APIURI__}/${escape(fileName)}?size=${size}&thumbnailRatio=${ratio}`
  }

  _getMiniSrc = (props) => {
    const { fileName, ratio } = props
    return `https://${__APIURI__}/${escape(fileName)}?size=10&thumbnailRatio=${ratio}`
  }

  _onClick = () => {
    this.props.selectPhoto(this.props.index)
  }

  _addListeners = () => {
    if (!this._lazyLoadHandler()) {
      window.addEventListener('resize', this._lazyLoadHandler)
      window.addEventListener('scroll', this._lazyLoadHandler)
    }
  }

  _detachListeners = () => {
    window.removeEventListener('resize', this._lazyLoadHandler)
    window.removeEventListener('scroll', this._lazyLoadHandler)
  }

  render () {
    const { bounce } = this.state
    const boxClassArray = this.props.className ? [this.props.className] : []

    boxClassArray.push(classes.photoBox)
    boxClassArray.push(classes[this.props.ratio])

    const imageCropClassArray = [classes.imageCrop]
    if (bounce) imageCropClassArray.push(classes.bounce)

    const backgroundImageStyle = {
      backgroundImage: this.state.imgData
        ? 'url(' + this.state.imgData + ')'
        : 'url("' + this._getMiniSrc(this.props) + '")',
      filter: this.state.imgData ? null : 'blur(0.4em) saturate(2)'
    }

    return (
      <div key={this.props.index} ref={(self) => { this._selfRef = self }} className={boxClassArray.join(' ')}>
        <div
          className={classes.rowSizer}
          // data-src={this._getSrc(this.props)}
          onClick={this._onClick}
        >
          <div className={imageCropClassArray.join(' ')}>
            <div className={classes.image} style={backgroundImageStyle} />
            {this.state.showImage
              ? null
              : <LoadingImage />}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, {
  selectPhoto
})(PhotoBox)
