import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

// NOTE: here we're making use of the `resolve.root` configuration
// option in webpack, which allows us to specify import paths as if
// they were from the root of the ~/src directory. This makes it
// very easy to navigate to files regardless of how deeply nested
// your current file is.
import CoreLayout from 'layouts/CoreLayout'
import ContactView from 'views/ContactView'
import PhotoView from 'views/PhotoView'
import NotFoundView from 'views/NotFoundView'

const Routes = () => (
  <CoreLayout>
    <Switch>
      <Route exact={true} path='/' render={Routes.homeRedirect} />
      {
        /* istanbul ignore next : ignore dev only branch */
        __DEV__ ? <Route path='/test' component={require('views/HomeView').default} /> : null
      }
      <Route path='/contact' component={ContactView} />
      <Route path='/photos/:folder*' component={PhotoView} />
      <Route path='*' component={NotFoundView} status={404} />
    </Switch>
  </CoreLayout>
)

Routes.homeRedirect = () => (
  <Redirect to='/photos' />
)

export default Routes
