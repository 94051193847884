import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { togglePath } from 'redux/modules/sideBar'
import Triangle from './Triangle'
import classes from './SideBar.scss'

export function ParentLink (props) {
  const { active, open, to, children, togglePath, ...otherProps } = props

  function handleOnClick (e) {
    e.preventDefault()
    togglePath(to, !open)
  }

  const linkClasses = [classes.parentLink]
  if (active) linkClasses.push(classes.activeLink)
  if (open) linkClasses.push(classes.openLink)

  return (
    <a
      {...otherProps}
      className={linkClasses.join(' ')}
      onClick={handleOnClick}
    >{children}<Triangle active={open} />
    </a>
  )
}

ParentLink.propTypes = {
  children: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  togglePath: PropTypes.func.isRequired
}

export default connect(null, { togglePath })(ParentLink)
