/* @flow */
// ------------------------------------
// Constants
// ------------------------------------
export const JSON_DATA_ERROR = 'JSON_DATA_ERROR'
export const SERVER_404_ERROR = 'SERVER_404_ERROR'

// ------------------------------------
// Actions
// ------------------------------------
export function jsonDataError (error = {}) {
  return {
    type: JSON_DATA_ERROR,
    payload: error
  }
}

export function server404Error (error = {}) {
  return {
    type: SERVER_404_ERROR,
    payload: error
  }
}

export const actions = {
  jsonDataError,
  server404Error
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [JSON_DATA_ERROR]: (state, payload) => {
    const message = payload.message || ''

    console.error('An error occured: ' + message)
    console.log(payload)
    return Object.assign({}, state, { lastError: payload, message: message })
  },
  [SERVER_404_ERROR]: (state, payload) => {
    const message = payload.message || ''

    console.error('An error occured: ' + message)
    console.log(payload)
    return Object.assign({}, state, { lastError: payload, message: message })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  message: null,
  lastError: null
}
export default function errorReducer (state = Object.assign({}, initialState), action = {}) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action.payload) : state
}
