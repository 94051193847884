import PropTypes from 'prop-types'

const PhotoShape = PropTypes.shape({
  fileName: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
})

export default PhotoShape
