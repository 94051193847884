import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import 'styles/core.scss'
import SideBar from './components/SideBar'
import classes from './CoreLayout.scss'

// Note: Stateless/function components *will not* hot reload!
// react-transform *only* works on component classes.
//
// Since layouts rarely change, they are a good place to
// leverage React's new Stateless Functions:
// https://facebook.github.io/react/docs/reusable-components.html#stateless-functions
//
// CoreLayout is a pure function of its props, so we can
// define it with a plain javascript function...
function CoreLayout ({ children }) {
  return (
    <div className={classes.pageContainer}>
      <Helmet
        defaultTitle='The Treehouse'
        link={[{ rel: 'canonical', href: window.location }]}
      />
      <SideBar className={classes.sidebarContainer} />
      <div className={classes.viewContainer}>
        {children}
      </div>
    </div>
  )
}

CoreLayout.propTypes = {
  children: PropTypes.element
}

export default CoreLayout
