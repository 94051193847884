import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'
import { routerMiddleware } from 'connected-react-router'

export default function configureStore (history, initialState = {}) {
  // Compose final middleware and use dev-tools in dev environment
  let middleware = compose(applyMiddleware(routerMiddleware(history), thunk))
  /* istanbul ignore if : code will only run in dev */
  if (__DEV__ && window.__REDUX_DEVTOOLS_EXTENSION__) {
    middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__())
  }

  // Create final store and subscribe router in dev env ie. for devtools
  const store = createStore(rootReducer(history), initialState, middleware)
  /* istanbul ignore if : code will only run in dev */
  if (__DEV__ && module.hot) {
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default(history)
      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}
