import React from 'react'
import PropTypes from 'prop-types'
import NavLink from './NavLink'
import ParentLink from './ParentLink'
import DirectoryShape from './DirectoryShape'
import classes from './SideBar.scss'

export default class MenuItem extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    level: PropTypes.number,
    children: PropTypes.string.isRequired,
    subFolders: PropTypes.arrayOf(DirectoryShape),
    selectedPath: PropTypes.string,
    selectedRoute: PropTypes.string
  }

  static defaultProps = { level: 1 };

  constructor (props) {
    super(props)
    this.state = {
      height: 'auto'
    }
  }

  componentDidMount () {
    this._setHeight()
  }

  shouldComponentUpdate (nextProps, nextState) {
    const props = this.props
    const state = this.state

    const activeStatusChaged =
      (props.selectedRoute !== nextProps.selectedRoute && this._isActive(nextProps)) ||
      (this._isActive(props) !== this._isActive(nextProps))

    const openStatusChaged =
      (props.selectedPath !== nextProps.selectedPath && this._isOpen(nextProps)) ||
      (this._isOpen(props) !== this._isOpen(nextProps))

    const toChanged = nextProps.to !== props.to
    const childrenChanged = props.children !== nextProps.children
    const subFoldersChanged = props.subFolders !== nextProps.subFolders
    const heightChanged = state.height !== nextState.height

    return heightChanged ||
          activeStatusChaged ||
          openStatusChaged ||
          toChanged ||
          childrenChanged ||
          subFoldersChanged
  }

  componentDidUpdate () {
    this._setHeight()
  }

  _isActive = (props) => {
    return props.subFolders && props.subFolders.length > 0
      ? (props.selectedRoute !== undefined && props.selectedRoute.indexOf(props.to) === 0)
      : (props.selectedRoute === props.to)
  }

  _isOpen = (props) => {
    return props.subFolders && props.subFolders.length > 0 &&
      props.selectedPath !== undefined && props.selectedPath.indexOf(props.to) === 0
  }

  _setHeight = () => {
    if (!this._subMenu) return

    const open = this._isOpen(this.props)
    let height = 0
    if (open) {
      for (const child of this._subMenu.children) {
        for (const childChild of child.children) {
          if (childChild.tagName === 'UL') {
            height += Number.parseInt(childChild.style.height)
          } else {
            height += childChild.getBoundingClientRect().height
          }
        }
      }
    }
    this._subMenu.style.height = `${height}px`
  }

  render () {
    const { to, level, children, selectedPath, selectedRoute } = this.props
    const subFolders = this.props.subFolders || []
    const active = this._isActive(this.props)

    if (subFolders.length > 0) {
      const open = this._isOpen(this.props)
      const subMenus = []
      for (const value of subFolders) {
        subMenus.push(
          <MenuItem
            to={value.folder}
            key={value.folder}
            level={level + 1}
            subFolders={value.subFolders}
            selectedPath={selectedPath}
            selectedRoute={selectedRoute}
          >
            {value.folderName}
          </MenuItem>
        )
      }
      return (
        <li className={classes['level' + level]}>
          <ParentLink
            to={to}
            active={active}
            open={open}
          >
            {children}
          </ParentLink>
          <ul
            ref={(ref) => { this._subMenu = ref }}
          >
            {subMenus}
          </ul>
        </li>
      )
    } else {
      return (
        <li className={classes['level' + level]}>
          <NavLink to={to} active={active}>{children}</NavLink>
        </li>
      )
    }
  }
}
