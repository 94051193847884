import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classes from './NotFoundView.scss'

import HeadingUnderlined from 'components/HeadingUnderlined'

export const NotFoundView = ({ history }) => {
  function goBack (e) {
    e.preventDefault()
    history.goBack()
  }

  return (
    <div>
      <HeadingUnderlined>Page not found</HeadingUnderlined>
      <p><a className={classes.link} href='#' onClick={goBack}>Click here to return from whence you came</a></p>
    </div>
  )
}

NotFoundView.propTypes = {
  history: PropTypes.object.isRequired
}

export default connect()(NotFoundView)
