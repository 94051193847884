// Global
import React from 'react'
import PropTypes from 'prop-types'

const CameraImage =
  ({ className }) =>
    (
      <svg className={className} width='32' height='32' viewBox='0 0 32 32'>
        <path d='M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30
        8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9
        2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902
        0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z'
        />
      </svg>
    )

CameraImage.propTypes = {
  className: PropTypes.string
}
export default CameraImage
