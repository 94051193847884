import 'es6-promise/auto'
import 'isomorphic-fetch'
import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import configureStore from './redux/configureStore'
import Routes from './routes/index'
import Root from './containers/Root'

// ========================================================
// Browser History Setup
// ========================================================
const browserHistory = createBrowserHistory()

// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-redux-router. We have installed the
// react-redux-router reducer under the routerKey "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-redux-router of its location.
const initialState = window.___INITIAL_STATE__
const store = configureStore(browserHistory, initialState)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')

let render = routes => {
  // Now that we have the Redux store, we can create our routes. We provide
  // the store to the route definitions so that routes have access to it for
  // hooks such as `onEnter`.

  let rootElement = <Root history={browserHistory} routes={routes} store={store} />

  /* istanbul ignore if : This code is excluded from production bundle */
  if (__DEV__ && module.hot) {
    console.log('hot')
    const AppContainer = require('react-hot-loader').AppContainer
    const rootTemp = rootElement
    rootElement = <AppContainer>{rootTemp}</AppContainer>
  }
  ReactDOM.render(
    rootElement,
    MOUNT_NODE
  )
}

/* istanbul ignore if : This code is excluded from production bundle */
if (__DEV__ && module.hot) {
  // Development render functions
  const renderApp = render
  const renderError = (error) => {
    const RedBox = require('redbox-react').default
    ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
  }

  // Wrap render in try/catch
  render = routes => {
    try {
      renderApp(routes)
    } catch (error) {
      renderError(error)
    }
  }

  // Setup hot module replacement
  module.hot.accept('./routes/index', () => {
    const routes = require('./routes/index')
    render(routes.default())
  })
}

// Now that redux and react-router have been configured, we can render the
// React application to the DOM!
render(<Routes />)
