
let webPSupport

export default function canUseWebP () {
  if (webPSupport !== undefined) return webPSupport

  const element = document.createElement('canvas')
  if (element.getContext && element.getContext('2d')) {
    // was able or not to get WebP representation
    webPSupport = element.toDataURL('image/webp').indexOf('data:image/webp') === 0
    return webPSupport
  }

  // very old browser like IE 8, canvas not supported
  webPSupport = false
  return webPSupport
}
