import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toggleMenu } from 'redux/modules/sideBar'
import { Link } from 'react-router-dom'
import classes from './SideBar.scss'

export function NavLink (props) {
  const { active, toggleMenu, ...otherProps } = props
  return (
    <Link
      {...otherProps}
      className={classes.navLink + (active ? ' ' + classes.activeLink : '')}
      onClick={toggleMenu}
    />
  )
}

NavLink.propTypes = {
  children: PropTypes.string.isRequired,
  active: PropTypes.bool,
  to: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func.isRequired
}

NavLink.defaultProps = {
  active: false
}

export default connect(null, {
  toggleMenu
})(NavLink)
