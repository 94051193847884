import Spacer from './Spacer'
import React from 'react'
import getMaxKey from 'functions/getMaxKey'
import classes from '../PhotoView.scss'

export default class PhotoSizer {
  constructor (photoCount = 0) {
    this._photoCount = photoCount
  }

  _photoCount = 0;
  _currentIndex = 0;
  _columnCount = { 3: 0, 6: 0, 9: 0, 12: 0 };
  _bias = {
    oneToOne: 0,
    doubleWidth: 0,
    tripleWidth: 0,
    doubleHeight: 0,
    tripleHeight: 0,
    double: 0
  };

  _spacers = { 3: [], 6: [], 9: [], 12: [] };

  getPictureSizing (photo) {
    const photoDetails = {
      fileName: photo.fileName,
      index: this._currentIndex,
      key: this._currentIndex
    }
    photoDetails.ratio = this._getRatio(photo.height, photo.width)
    this._setSpacers(photoDetails.ratio)
    photoDetails.className = this._getExtraClasses(photoDetails.ratio)

    this._currentIndex++
    return photoDetails
  }

  getSpacers () {
    const spacers = []
    for (let rowType of Object.keys(this._columnCount)) {
      rowType = parseInt(rowType)
      spacers.push(...this._getSpacersByRowType(rowType))
      if (this._currentIndex === this._photoCount) {
        for (let i = 0; (this._columnCount[rowType] + i) % rowType !== 0 ||
          this._nextRowHasSpacers(this._columnCount[rowType] + i, rowType, 0); i++) {
          spacers.push(this._getSpacerElement(rowType, this._columnCount[rowType] + 1 + i))
        }
      }
    }
    return spacers
  }

  _addSpacer (rowType, column) {
    this._spacers[rowType][column] = this._spacers[rowType][column] ? this._spacers[rowType][column] + 1 : 1
  }

  _removeSpacer (rowType, column) {
    this._spacers[rowType][column] = this._spacers[rowType][column] > 0 ? this._spacers[rowType][column] - 1 : 0
  }

  _checkForSpacer (rowType, column) {
    return this._spacers[rowType][column] > 0
  }

  _setSpacers (ratio) {
    // these types don't have extra spaces so return early
    switch (ratio) {
      case 'oneToOne':
      case 'doubleWidth':
      case 'tripleWidth':
        return
    }
    for (let rowType of Object.keys(this._columnCount)) {
      rowType = parseInt(rowType)
      switch (ratio) {
        case 'doubleHeight':
          this._addSpacer(rowType, this._columnCount[rowType] + rowType)
          break
        case 'tripleHeight':
          this._addSpacer(rowType, this._columnCount[rowType] + rowType)
          this._addSpacer(rowType, this._columnCount[rowType] + rowType + rowType)
          break
        case 'double':
          this._addSpacer(rowType, this._columnCount[rowType] + rowType)
          this._addSpacer(rowType, this._columnCount[rowType] + rowType + 1)
          break
      }
    }
  }

  _getSpacerElement (rowType, column) {
    return (<Spacer spacerClass={'spacer' + rowType} key={'spacer' + rowType + '-' + column} />)
  }

  _getSpacersByRowType (rowType) {
    const spacers = []
    this._columnCount[rowType]++
    if (this._checkForSpacer(rowType, this._columnCount[rowType])) {
      spacers.push(this._getSpacerElement(rowType, this._columnCount[rowType]))
      spacers.push(...this._getSpacersByRowType(rowType))
    }
    return spacers
  }

  _nextRowHasSpacers (columnCount, rowType, row) {
    return row < rowType &&
      (this._checkForSpacer(rowType, columnCount + row) ||
        this._nextRowHasSpacers(columnCount, rowType, row + 1))
  }

  _getRatio (height, width) {
    const internalBias = Object.assign({}, this._bias)
    for (const key of Object.keys(this._bias)) {
      this._bias[key] += 0.2
    }
    const photoAverage = this._currentIndex / this._columnCount[12]
    if (this._currentIndex + 1 >= this._photoCount - (photoAverage * 12)) {
      // Last row
      internalBias.doubleWidth *= 2
      internalBias.tripleWidth *= 2
      if (this._currentIndex + 1 === this._photoCount) {
        // last photo should always be a triple to fill as much of the last row as possible
        internalBias.tripleWidth = 100
      }
      internalBias.doubleHeight = -100
      internalBias.tripleHeight = -100
      internalBias.double = -100
    } else if (this._currentIndex + 1 >= this._photoCount - (photoAverage * 24)) {
      // Second to last row
      internalBias.doubleHeight *= 2
      internalBias.doubleHeight *= 2
      internalBias.tripleHeight = -100
      internalBias.double *= 2
    } else if (this._currentIndex + 1 >= this.photoCount - (photoAverage * 36)) {
      // Third to last row
      internalBias.tripleHeight *= 2
    }
    internalBias.oneToOne += 1

    // doubleHeight
    if (width < height / 1.5) {
      internalBias.doubleHeight += 3
    } else if (width < height) {
      internalBias.doubleHeight += 2
    }
    // tripleHeight
    if (width < height / 2.5) {
      internalBias.tripleHeight += 5
    }

    // tripleWidth
    if (width > height * 2.5) {
      internalBias.tripleWidth += 3
    }

    // doubleWidth
    if (width > height * 1.5) {
      internalBias.doubleWidth += 2
    }

    // doubleWidth
    if (width < height * 1.5 && width > height) {
      internalBias.double += 2
    }

    const ratio = getMaxKey(internalBias)
    // reset the chosen bias one;
    this._bias[ratio] = 0
    return ratio
  }

  _getExtraClasses (ratio) {
    const classNames = []
    switch (ratio) {
      // these types don't have extra classes so return early
      case 'oneToOne':
      case 'doubleHeight':
      case 'tripleHeight':
        return ''
    }

    for (let rowType of Object.keys(this._columnCount)) {
      rowType = parseInt(rowType)
      const typeCount = this._columnCount[rowType]
      const extraClasses = { none: 1, short: 0, extrashort: 0 }

      switch (ratio) {
        case 'tripleWidth':
        // third column would fall outside the page or would overlap spacer
          if ((typeCount + 2) % rowType === 0 || this._checkForSpacer(rowType, typeCount + 2)) {
            extraClasses.short = 2
          }
        /* eslint-disable-next-line no-fallthrough */
        case 'doubleWidth':
        case 'double':
        // second column would fall outside the page or would overlap spacer
          if ((typeCount + 1) % rowType === 0 || this._checkForSpacer(rowType, typeCount + 1)) {
            extraClasses.extrashort = 3
          }
          break
      }

      const extraClass = getMaxKey(extraClasses)
      switch (extraClass) {
        case 'none':
          if (ratio === 'tripleWidth') {
            this._columnCount[rowType]++
          }
          this._columnCount[rowType]++
          break
        case 'short':
          classNames.push(extraClass + rowType)
          this._columnCount[rowType]++
          break
        case 'extrashort':
          classNames.push(extraClass + rowType)
          if (ratio === 'double') {
            this._removeSpacer(rowType, typeCount + rowType + 1)
          }
          break
      }
    }
    return classNames.map((className) => classes[className]).join(' ')
  }
}
