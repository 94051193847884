export default function getPhotoFolderName (folder, short = false) {
  const folderArr = (folder || '')
    .replace('/photos/', '')
    .split('/').map((folder) => {
      return folder.replace(/^\d\d-/, '')
        .replace(/-/g, ' ')
        .replace(' and ', ' & ')
    })
  return short
    ? folderArr.pop()
    : folderArr.join(' - ')
}
