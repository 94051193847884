import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import error from './modules/error'
import photos from './modules/photos'
import sideBar from './modules/sideBar'

/* istanbul ignore next : ignore dev only branch */
const counter = __DEV__ ? require('./modules/counter').default : null

export default (history) => combineReducers({
  counter,
  error,
  photos,
  sideBar,
  router: connectRouter(history)
})
